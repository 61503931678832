import React from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import ContentBlock from "../../shared/sub/contentBlock";
import WhatAndHowSection from "../mortgage-repayment/whatAndHowStyle";

const WhatAndHow = () => {
  const {
    sanityCalculatorFinanceCostPage: { howToApply, howToApplySecond },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorFinanceCostPage {
        howToApply {
          _rawContent(resolveReferences: { maxDepth: 5 })
        }
        howToApplySecond {
          _rawContent(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  `);

  return (
    <WhatAndHowSection>
      <div className="disclaimer-content">
        <p>
          Please read our{" "}
          <span onClick={() => navigate("/website-disclaimer")}>Disclaimer Statement</span> for more
          information on these results.
        </p>
      </div>
      <div className="content-column">
        <ContentBlock blocks={howToApply?._rawContent} />
        <ContentBlock blocks={howToApplySecond?._rawContent} />
      </div>
    </WhatAndHowSection>
  );
};

export default WhatAndHow;
