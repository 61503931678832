import React, { ReactNode } from "react";
import { Link as GLink } from "gatsby";

import { Maybe, SanityLink } from "@graphql-types";
import { mergeClassNames } from "@util/helpers";

interface Props {
  children?: React.ReactNode;
  url?: string;
  data?: Maybe<SanityLink>;
  className?: string;
  design?: "auto" | "cream" | "maroon";
  endIcon?: ReactNode;
}

const Children = ({ children, data, endIcon }: Props) => {
  return (
    <>
      {children ?? data?.linkText}{" "}
      {endIcon ? <span style={{ marginLeft: 5 }}>{endIcon}</span> : null}
    </>
  );
};

const Link = (props: Props) => {
  const { children, url, className, design, data } = props;
  const classes = mergeClassNames("link", design ? `design ${design}` : "", className);

  if (data?.url) {
    return (
      <GLink to={data.url} className={classes} activeClassName="active">
        <Children {...props} />
      </GLink>
    );
  }

  if (url) {
    if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
      return (
        <a href={url} target="_blank" className={classes}>
          <Children {...props} />
        </a>
      );
    }

    if (url.indexOf("mailto:") === 0 || url.indexOf("tel:") === 0 || url.indexOf("#") === 0) {
      return (
        <a href={url} className={classes}>
          <Children {...props} />
        </a>
      );
    }

    return (
      <GLink to={url} className={classes} activeClassName="active">
        <Children {...props} />
      </GLink>
    );
  }

  return children;
};

export default Link;
