import { colors, colorsRGB } from "@util/constants";
import { getCssVar, mergeClassNames } from "@util/helpers";
import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  design?: "auto" | "cream" | "maroon" | "maroonFilled";
  endIcon?: ReactNode;
}

const Button = (props: Props) => {
  const { children, endIcon, className, design = "auto", ...rest } = props;
  const classes = mergeClassNames("button", design ? `design ${design}` : "", className);

  return (
    <StyledButton {...rest} className={classes}>
      {children}
      {endIcon ? <span style={{ marginLeft: 5 }}>{endIcon}</span> : null}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
--block-bg-color: ${colors.white};
--block-text-color: ${colors.maroon};
--block-border-color: ${colors.white};
--block-light-color: rgba(157, 137, 120, 0.1);
cursor: pointer;

      transition: all 0.2s ease-out;
      border: 0;
      cursor: pointer;
      background-color: transparent;
      border: none;
      &:focus {
        outline: none;
      }

      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }

      &.design {
        width: fit-content;
        text-align: center;
        border: 1px solid;
        border-radius: 4px;
        padding: 8px 20px;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          text-decoration: none;
          opacity: 1;
        }

        &.cream {
          color: ${colors.cream};
          border-color: ${colors.cream};

          &:hover,
          &.active {
            color: ${colors.maroon};
            background-color: ${colors.cream};
          }
        }

        &.maroon {
          color: ${colors.maroon};
          border-color: ${colors.maroon};

          &:hover,
          &.active {
            color: ${colors.cream};
            background-color: ${colors.maroon};
          }
        }

        &.maroonFilled {
          color: ${colors.cream};
          background-color: ${colors.maroon};
          border-color: ${colors.maroon};

          &:hover,
          &.active {
            color: ${colors.cream};
            background-color: ${colorsRGB.maroon(0.8)};
          }
        }

        &.auto {
          color: ${getCssVar("blockTextColor")};
          border-color: ${getCssVar("blockTextColor")};

          &:hover,
          &.active {
            color: ${getCssVar("blockBgColor")};
            background-color: ${getCssVar("blockTextColor")};
          }
        
      
`;
