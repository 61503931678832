import {
  PDFDocument,
  PDFFont,
  PDFPage,
  PDFPageDrawTextOptions,
  RGB,
  StandardFonts,
  rgb,
} from "pdf-lib";
import { lineFeePeriodLabel, termPeriodLabel } from "./utils";
import { formattedNumber, numberToCurrency } from "@util/helpers";

const exportToPdf = async (calculators: FinanceState<string>[]) => {
  try {
    const response = await fetch(`/finance-calculator.pdf`);
    if (!response.ok) {
      throw new Error(`Failed to fetch HTML file: ${response.status} ${response.statusText}`);
    }
    const arrayBuff = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuff);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const pages = pdfDoc.getPages();
    const pageToEdit = pages[1];
    const { height } = pageToEdit.getSize();

    const getY = (val: number) => (val > height ? height : height - val);

    calculators.forEach((calculator, index) => {
      const x = 190 + 124 * index;
      const maroonColor = rgb(0.33, 0.11, 0.17);
      const creamColor = rgb(1, 0.96, 0.94);

      drawTexts(pageToEdit, x, maroonColor, helveticaFont, [
        // Inputs
        { text: numberToCurrency(calculator.limit), y: getY(210) },
        { text: `${calculator.term} ${termPeriodLabel[calculator.termPeriod]}`, y: getY(235) },
        { text: numberToCurrency(calculator.openingBalance), y: getY(260) },
        { text: `${formattedNumber(calculator.interestRate)}%`, y: getY(285) },
        {
          text: `${formattedNumber(calculator.lineFee)}% ${
            lineFeePeriodLabel[calculator.lineFeePeriod]
          }`,
          y: getY(313),
        },
        { text: `${formattedNumber(calculator.establishmentFee)}%`, y: getY(340) },
        { text: numberToCurrency(calculator.monthlyManagementFee), y: getY(368) },
        { text: `${formattedNumber(calculator.brokerFee)}%`, y: getY(394) },
      ]);
      // Result
      drawTexts(pageToEdit, x, creamColor, helveticaFont, [
        { text: numberToCurrency(calculator.result?.interestCost), y: getY(475) },
        { text: numberToCurrency(calculator.result?.lineFee), y: getY(505) },
        { text: numberToCurrency(calculator.result?.establishmentFee), y: getY(535) },
        { text: numberToCurrency(calculator.result?.managementFee), y: getY(568) },
        { text: numberToCurrency(calculator.result?.brokerFee), y: getY(602) },
        { text: numberToCurrency(calculator.result?.total), y: getY(662) },
        { text: `${formattedNumber(calculator.result?.financeRate)}%`, y: getY(695) },
        { text: numberToCurrency(calculator.result?.facilityLimit), y: getY(726) },
      ]);
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "Development Finance Cost Calculator.pdf";
    link.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error reading HTML file:", error);
    alert("Error on generate PDF, please try again");
  }
};

export default exportToPdf;

const drawTexts = (
  pg: PDFPage,
  x: number,
  color: RGB,
  helveticaFont: PDFFont,
  list: ({ text: string } & PDFPageDrawTextOptions)[],
) => {
  list.forEach(el => {
    const textSize = 12;
    const textWidth = helveticaFont.widthOfTextAtSize(el.text, textSize);
    const offset = (124 - textWidth) / 2;

    pg.drawText(el.text, {
      size: textSize,
      x: x + offset,
      color,
      font: helveticaFont,
      ...el,
    });
  });

  return pg;
};
