import { stringToNumber } from "@util/helpers";
import computations from "./computations";

export const INITIAL_STATE: FinanceState = {
  limitType: "principal", // A1
  limit: "", // B1
  term: "1.5", // B2
  termPeriod: "year", // C2
  openingBalance: "", // B3
  interestRate: "10", // B4
  lineFee: "1", // B5
  lineFeePeriod: "year", // C5
  establishmentFee: "2", // B6
  monthlyManagementFee: "", // B7
  brokerFee: "1", // B8
  showResult: false,
  changed: false,
};

export const termPeriodLabel = {
  year: "Years",
  month: "Months",
};
export const lineFeePeriodLabel = {
  year: "Annually",
  month: "Monthly",
};

const getPerc = (val: number) => val / 100;

export const formatInputsToSave = (
  inputs: FinanceState,
): { formatted: FinanceStateFormatted; result: FinanceStateResult } => {
  const data = JSON.parse(JSON.stringify(inputs)) as Partial<FinanceState>;

  delete data.changed;
  delete data.formatted;
  delete data.showResult;
  delete data.result;

  const formatted = {
    ...(data as FinanceState),
    limit: stringToNumber(data.limit),
    term: stringToNumber(data.term),
    openingBalance: stringToNumber(data.openingBalance),
    interestRate: stringToNumber(data.interestRate),
    lineFee: stringToNumber(data.lineFee),
    establishmentFee: stringToNumber(data.establishmentFee),
    monthlyManagementFee: stringToNumber(data.monthlyManagementFee),
    brokerFee: stringToNumber(data.brokerFee),
  };

  const computationParams = {
    ...formatted,
    interestRate: getPerc(formatted.interestRate),
    brokerFee: getPerc(formatted.brokerFee),
    lineFee: getPerc(formatted.lineFee),
    establishmentFee: getPerc(formatted.establishmentFee),
  } as FinanceStateFormatted;

  const interestCostRes = computations.interestCost(computationParams);
  const lineFeeRes = computations.lineFee(computationParams);
  const establishmentRes = computations.establishmentFee(computationParams);
  const managementRes = computations.managementFee(computationParams);
  const brokerRes = computations.brokerFee(computationParams);
  const total = interestCostRes + lineFeeRes + establishmentRes + managementRes + brokerRes;
  const totalFacility = computations.facilityLimit(computationParams, total);

  return {
    formatted: formatted,
    result: {
      interestCost: interestCostRes,
      lineFee: lineFeeRes,
      establishmentFee: establishmentRes,
      managementFee: managementRes,
      brokerFee: brokerRes,
      total,
      facilityLimit: totalFacility,
      financeRate: computations.financeRate(computationParams, total, totalFacility),
    },
  };
};
