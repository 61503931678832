import React from "react";

import Heading from "../../../global/heading";
import styled from "styled-components";
import { colors, colorsRGB, fontWeights } from "@util/constants";
import { formattedNumber, numberToCurrency } from "@util/helpers";

interface Props {
  result: FinanceStateResult;
}

const Results = ({ result }: Props) => {
  return (
    <Wrapper>
      <Heading design="h5" tag="p">
        Results
      </Heading>
      <ul>
        <li>
          <span className="label">Interest Cost</span>
          <span className="result">{numberToCurrency(result.interestCost)}</span>
        </li>
        <li>
          <span className="label">Line Fee</span>
          <span className="result">{numberToCurrency(result.lineFee)}</span>
        </li>
        <li>
          <span className="label">Establishment Fee</span>
          <span className="result">{numberToCurrency(result.establishmentFee)}</span>
        </li>
        <li>
          <span className="label">Management Fee</span>
          <span className="result">{numberToCurrency(result.managementFee)}</span>
        </li>
        <li>
          <span className="label">Broker Fee</span>
          <span className="result">{numberToCurrency(result.brokerFee)}</span>
        </li>
        <li>
          <span className="label">Total Finance Cost</span>
          <span className="result">{numberToCurrency(result.total)}</span>
        </li>
        <li>
          <span className="label">Effective Finance Rate</span>
          <span className="result">{formattedNumber(result.financeRate)}%</span>
        </li>
        <li>
          <span className="label">Facility Limit</span>
          <span className="result">{numberToCurrency(result.facilityLimit)}</span>
        </li>
      </ul>
    </Wrapper>
  );
};

export default Results;

const Wrapper = styled.div`
  .period-payment {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${colorsRGB.white(0.4)};
    .label {
      opacity: 1;
    }
    .result {
      font-weight: ${fontWeights.bold};
    }
  }

  ul {
    margin-top: 20px;

    li {
      font-size: 16px;
      color: ${colors.cream};
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      .label {
        font-size: 14px;
        opacity: 0.7;
      }
    }
  }
`;
