import React from "react";

import TextAndButton from "../../components/calculators/mortgage-repayment/textAndButton";
import BlogsSection from "../../components/shared/blogsSection/";
import { Query } from "@graphql-types";
import { colors, fontWeights, headerHeight, mediaQuery } from "@util/constants";
import { useStaticQuery, graphql } from "gatsby";
import { Header } from "@global";
import FinanceCalculator from "@components/calculators/finance/finance";
import { getCssVar } from "@util/helpers";
import styled from "styled-components";
import { Container } from "@util/standard";
import WhatAndHow from "@components/calculators/finance/whatAndHow";

const FinanceCost = () => {
  const { sanityCalculatorFinanceCostPage }: Query = useStaticQuery(graphql`
    query {
      sanityCalculatorFinanceCostPage {
        title
        categories {
          colorList
        }
      }
    }
  `);

  if (sanityCalculatorFinanceCostPage == null) return null;
  const { title, categories } = sanityCalculatorFinanceCostPage;

  const categoryColor = categories && categories[0] && categories[0].colorList;

  const headerData = {
    headerColor: categoryColor ?? colors.maroon,
  };

  return (
    <div className="subpage-container">
      <Header title={title} headerData={headerData} />
      <WhatAndHow />
      <Wrapper>
        <Container maxWidth="1440px" width="80%" margin="auto" mobileWidth="90%">
          <FinanceCalculator />
        </Container>
      </Wrapper>
      <TextAndButton />
      <BlogsSection title="Blogs" />
    </div>
  );
};

export default FinanceCost;

const Wrapper = styled.div`
  .spaced-text {
    margin: 20px 0 10px;
  }

  .calculate-button {
    margin-top: 30px;
  }

  .payment-value {
    font-size: 30px;
    font-weight: ${fontWeights.bold};

    ${mediaQuery.tabletDown} {
      font-size: 24px;
    }
  }

  .inline {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

    &.split {
      justify-content: space-between;
      width: 100%;
    }

    .slider {
      flex: 1;
    }
  }

  .inputs-result {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    ${mediaQuery.tabletDown} {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .results-block,
  .inputs-block {
    border-radius: 5px;
    padding: 40px;
    background-color: ${getCssVar("blockLightColor")};

    ${mediaQuery.tabletDown} {
      padding: 20px;
    }
  }

  .results-block {
    background-color: ${colors.maroon};
    color: ${colors.cream};
    border: 1px solid ${getCssVar("blockBorderColor")};
    height: fit-content;
    position: sticky;
    top: ${headerHeight.defaultInt + 20}px;
    min-height: 200px;

    &.full-height {
      height: 100%;
    }
  }
`;
