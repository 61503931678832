const AVG_UTILIZATION = 0.65; // H8

const interestCost = (params: FinanceStateFormatted) => {
  const {
    limitType,
    limit,
    term,
    openingBalance,
    interestRate,
    lineFee,
    establishmentFee,
    monthlyManagementFee,
    brokerFee,
    termPeriod,
  } = params;
  const isFacility = limitType === "facility";
  const isMonthTerm = termPeriod === "month";
  const termVal = isMonthTerm ? term / 12 : term;
  const monthlyFeeVal = isMonthTerm
    ? monthlyManagementFee * term
    : monthlyManagementFee * term * 12;

  const result =
    openingBalance * interestRate * termVal +
    (isFacility
      ? (limit - openingBalance) * AVG_UTILIZATION * interestRate * termVal
      : (limit +
          brokerFee * limit +
          monthlyFeeVal +
          limit * interestRate * AVG_UTILIZATION * termVal +
          limit * lineFee * termVal +
          limit * establishmentFee * termVal) *
        interestRate *
        AVG_UTILIZATION *
        termVal);

  return result;
};

const lineFee = (params: FinanceStateFormatted) => {
  const {
    limit,
    limitType,
    term,
    termPeriod,
    lineFee,
    lineFeePeriod,
    monthlyManagementFee,
    brokerFee,
    interestRate,
    establishmentFee,
  } = params;
  const isFacility = limitType === "facility";
  const isMonthTerm = termPeriod === "month";
  const isAnnualLineFee = lineFeePeriod === "year";
  const termVal = isMonthTerm ? term / 12 : term;

  const condition1 = isFacility
    ? limit
    : limit +
      limit * brokerFee +
      (isMonthTerm ? monthlyManagementFee * term : monthlyManagementFee * term * 12) +
      limit * interestRate * AVG_UTILIZATION * termVal +
      limit * lineFee * termVal +
      limit * establishmentFee * termVal;
  const condition2 =
    limit +
    limit * interestRate * AVG_UTILIZATION * termVal +
    limit * lineFee * termVal +
    limit * establishmentFee * termVal;

  const result = isAnnualLineFee
    ? lineFee * (isFacility ? limit : condition1)
    : lineFee * term * (isFacility ? limit : condition2);

  return result;
};

const establishmentFee = (params: FinanceStateFormatted) => {
  const {
    limit,
    limitType,
    term,
    termPeriod,
    lineFee,
    brokerFee,
    interestRate,
    establishmentFee,
    monthlyManagementFee,
  } = params;
  const isFacility = limitType === "facility";
  const isMonthTerm = termPeriod === "month";
  const termVal = isMonthTerm ? term / 12 : term;

  return isFacility
    ? establishmentFee * limit
    : establishmentFee *
        (limit +
          limit * brokerFee +
          (isMonthTerm ? term * monthlyManagementFee : monthlyManagementFee * term * 12) +
          limit * interestRate * AVG_UTILIZATION * termVal +
          limit * lineFee * termVal +
          limit * establishmentFee * termVal);
};

const managementFee = ({ monthlyManagementFee, term, termPeriod }: FinanceStateFormatted) => {
  return monthlyManagementFee * (termPeriod === "month" ? term : term * 12);
};

const brokerFee = (params: FinanceStateFormatted) => {
  const {
    limit,
    limitType,
    termPeriod,
    brokerFee,
    interestRate,
    lineFee,
    term,
    monthlyManagementFee,
  } = params;

  const isFacility = limitType === "facility";
  const isMonthTerm = termPeriod === "month";
  const termVal = isMonthTerm ? term / 12 : term;

  return isFacility
    ? brokerFee * limit
    : brokerFee *
        (limit +
          limit * interestRate * AVG_UTILIZATION * termVal +
          limit * lineFee * termVal +
          limit * brokerFee +
          (isMonthTerm ? term * monthlyManagementFee : monthlyManagementFee * term * 12));
};

const financeRate = (params: FinanceStateFormatted, total: number, totalFacility: number) => {
  const { termPeriod, term } = params;
  const isMonthTerm = termPeriod === "month";
  return (total / totalFacility / term) * (isMonthTerm ? 12 : 1) * 100;
};

const facilityLimit = ({ limitType, limit }: FinanceStateFormatted, total: number) => {
  return limitType === "facility" ? limit : limit + total;
};

const computations = {
  interestCost,
  lineFee,
  establishmentFee,
  managementFee,
  brokerFee,
  financeRate,
  facilityLimit,
};
export default computations;
