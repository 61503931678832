import React, { useRef, useState } from "react";
import Inputs from "./inputs";
import Results from "./results";
import { INITIAL_STATE, formatInputsToSave } from "./utils";
// import { createDocument } from "@lib/firebase";

import styled from "styled-components";

import exportToPdf from "./exportToPdf";
import ResultsCard from "../resultsCard";
import { useGetIP } from "@util/hooks";
import Icons from "../../../global/icons";
import Button from "../../../global/button";
import { colors, mediaQuery } from "@util/constants";

interface CalculatorProps {
  inputs: FinanceState;
  ip: any;
  onChange: (val: Partial<FinanceState>) => void;
  onClose: null | (() => void);
  index: number;
  onExport: () => void;
}

const CalculatorItem = ({ inputs, ip, onChange, onClose, index, onExport }: CalculatorProps) => {
  const handleChangeInput = <T extends keyof FinanceState>(property: T, value: FinanceState[T]) => {
    onChange({
      [property]: value,
      showResult: false,
      changed: true,
    });
  };

  const handleSubmit = () => {
    const saveToFirebase = inputs.changed;
    const { formatted, result } = formatInputsToSave(inputs);

    onChange({
      formatted,
      result,
      changed: false,
      showResult: true,
    });

    // if (saveToFirebase) {
    //   createDocument("finance-cost", {
    //     ...formatted,
    //     userAgent: window.navigator.userAgent,
    //     ipAddress: ip?.ip ?? "",
    //     country: ip?.country ?? "",
    //   });
    // }
  };

  return (
    <CalculatorWrapper $multiple={Boolean(onClose)}>
      <Inputs inputs={inputs} changeInput={handleChangeInput} onSubmit={handleSubmit} />
      {onClose && (
        <>
          <button onClick={onClose} className="close-calculator">
            <Icons asset="close" size={16} />
          </button>
          <div className="option-label">
            <p>Option {index + 1}</p>
          </div>
        </>
      )}
      <ResultsCard>
        <Icons className="watermark" asset="logoIcon" size={200} />
        {inputs.showResult && inputs.result ? (
          <>
            <Results result={inputs.result} />
            <Button className="export-pdf" design="cream" onClick={onExport}>
              Export to PDF
            </Button>
          </>
        ) : null}
      </ResultsCard>
    </CalculatorWrapper>
  );
};

const FinanceCalculator = () => {
  const [calculators, setCalculators] = useState([INITIAL_STATE]);
  const ip = useGetIP();
  const maxList = calculators.length >= 3;
  const refList = useRef<HTMLDivElement>(null);

  const handleChange = (index: number, val: Partial<FinanceState>) => {
    setCalculators(p => p.map((item, i) => (i === index ? { ...item, ...val } : item)));
  };

  const handleAddNew = () => {
    setCalculators(p => [...p, INITIAL_STATE]);
  };

  const handleRemove = (index: number) => {
    setCalculators(p => p.filter((_, i) => i !== index));
  };

  const handleMoveSlide = (right?: boolean) => {
    if (!refList.current) return;
    const itemWidth = refList.current.firstElementChild?.clientWidth;
    if (!itemWidth) return;
    refList.current.scrollBy({ left: right ? itemWidth : -itemWidth, behavior: "smooth" });
  };

  const handleExport = () => {
    exportToPdf(calculators);
  };

  return (
    <Wrapper>
      {maxList && (
        <div className="nav-buttons">
          <button onClick={() => handleMoveSlide()}>
            <Icons asset="arrowRight" size={20} />
          </button>
          <button onClick={() => handleMoveSlide(true)}>
            <Icons asset="arrowRight" size={20} />
          </button>
        </div>
      )}
      <div className="list" ref={refList}>
        {calculators.map((item, index) => (
          <CalculatorItem
            ip={ip}
            onExport={handleExport}
            onClose={calculators.length > 1 ? () => handleRemove(index) : null}
            onChange={val => handleChange(index, val)}
            inputs={item}
            key={String(index)}
            index={index}
          />
        ))}
      </div>
      {!maxList && (
        <button onClick={handleAddNew} className="add-another">
          <Icons asset="add" size={50} />
          <span>Duplicate to compare another option</span>
        </button>
      )}
    </Wrapper>
  );
};

export default FinanceCalculator;

const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  position: relative;
  width: 100%;
  margin: 50px 0;

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    gap: 20px;
    margin: 30px 0;
  }

  .nav-buttons {
    position: absolute;
    top: 50%;
    left: -30px;
    right: -30px;
    display: flex;
    justify-content: space-between;

    ${mediaQuery.tabletDown} {
      display: none;
    }
    button:first-child {
      transform: rotate(180deg);
    }
    svg {
      display: block;
    }
  }

  .list {
    display: flex;
    gap: 40px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    ${mediaQuery.tabletDown} {
      gap: 10px;
    }
  }

  .add-another {
    border: 1px dashed ${colors.maroon};
    padding: 20px;
    height: auto;
    display: flex;
    min-width: 180px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
`;

const CalculatorWrapper = styled.div<{ $multiple: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-width: calc(50% - 20px);
  width: 100%;
  position: relative;
  scroll-snap-align: center;

  ${mediaQuery.tabletDown} {
    width: ${({ $multiple }) => ($multiple ? "90%" : "100%")};
    min-width: ${({ $multiple }) => ($multiple ? "90%" : "100%")};
  }

  .close-calculator {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .export-pdf {
    margin: 30px auto 0;
  }
  .option-label {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${colors.maroon};
    padding: 8px;

    p {
      font-size: 14px;
      color: ${colors.cream};
    }
  }
  ${mediaQuery.tabletDown} {
    gap: 20px;
  }

  .watermark {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
  }
`;
