import { Maybe } from "@graphql-types";
import { mergeClassNames } from "@util/helpers";
import React, { DOMAttributes, ReactNode } from "react";

interface Props {
  design?: HeadingDesign;
  tag?: HeadingTag;
  text?: string | null;
  textAlign?: string;
  children?: ReactNode;
  className?: string;
  data?: Maybe<any>;
  attr?: DOMAttributes<Element>;
}

const Heading = ({ design, tag, text, children, data, className, attr, textAlign }: Props) => {
  const finalDesign = design ?? data?.design ?? "h3";
  const Tag = (tag ?? data?.tag ?? finalDesign) as HeadingTag;
  const content = children ?? data?.text ?? text;
  const align = textAlign ?? data?.textAlign ?? "";

  if (!content) return null;
  return (
    <Tag
      className={mergeClassNames("heading", finalDesign, align ? `align-${align}` : "", className)}
      {...attr}
    >
      {content}
    </Tag>
  );
};

export default Heading;
