import React from "react";
import TextField from "../../../global/textField";
import Select from "../../../global/select";
import Button from "../../../global/button";

interface Props {
  inputs: FinanceState;
  changeInput: <T extends keyof FinanceState>(property: T, value: FinanceState[T]) => void;
  onSubmit: () => void;
}

const Inputs = ({ inputs, changeInput, onSubmit }: Props) => {
  // const user = useSignIn();

  return (
    <div className="inputs-block">
      <p className="spaced-text">Limit</p>
      <div className="inline">
        <TextField
          value={inputs.limit}
          onChange={e => {
            changeInput("limit", e.target.value);
          }}
          prefix="$"
          type="currency"
          width={200}
        />
        <Select
          selectedOption={inputs.limitType}
          onChange={val => {
            changeInput("limitType", val as FinanceLimitType);
          }}
          options={[
            { title: "Principal Limit", value: "principal" },
            { title: "Facility Limit", value: "facility" },
          ]}
        />
      </div>
      <p className="spaced-text">Term</p>
      <div className="inline">
        <TextField
          value={inputs.term}
          onChange={e => {
            changeInput("term", e.target.value);
          }}
          type="number"
          width={130}
        />
        <Select
          selectedOption={inputs.termPeriod}
          onChange={val => changeInput("termPeriod", val as FinancePeriod)}
          options={[
            { title: "Months", value: "month" },
            { title: "Years", value: "year" },
          ]}
        />
      </div>
      <p className="spaced-text">Opening Loan Balance</p>
      <TextField
        value={inputs.openingBalance}
        onChange={e => {
          changeInput("openingBalance", e.target.value);
        }}
        prefix="$"
        type="currency"
        width={200}
      />
      <p className="spaced-text">Interest Rate</p>
      <TextField
        value={inputs.interestRate}
        onChange={e => {
          changeInput("interestRate", e.target.value);
        }}
        suffix="%"
        type="number"
        width={130}
      />
      <p className="spaced-text">Line Fee</p>
      <div className="inline">
        <TextField
          value={inputs.lineFee}
          onChange={e => {
            changeInput("lineFee", e.target.value);
          }}
          suffix="%"
          type="number"
          width={130}
        />
        <Select
          selectedOption={inputs.lineFeePeriod}
          onChange={val => changeInput("lineFeePeriod", val as FinancePeriod)}
          options={[
            { title: "Monthly", value: "month" },
            { title: "Annually", value: "year" },
          ]}
        />
      </div>
      <p className="spaced-text">Establishment Fee</p>
      <TextField
        value={inputs.establishmentFee}
        onChange={e => {
          changeInput("establishmentFee", e.target.value);
        }}
        suffix="%"
        type="number"
        width={130}
      />
      <p className="spaced-text">Monthly Management Fee</p>
      <TextField
        value={inputs.monthlyManagementFee}
        onChange={e => {
          changeInput("monthlyManagementFee", e.target.value);
        }}
        prefix="$"
        type="currency"
        width={200}
      />
      <p className="spaced-text">Broker Fee</p>
      <TextField
        value={inputs.brokerFee}
        onChange={e => {
          changeInput("brokerFee", e.target.value);
        }}
        suffix="%"
        type="number"
        width={130}
      />
      <Button className="calculate-button" onClick={onSubmit}>
        Calculate finance cost
      </Button>
    </div>
  );
};

export default Inputs;
