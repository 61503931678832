import React, { ReactNode } from "react";

import styled from "styled-components";
import { mediaQuery } from "@util/constants";
import { mergeClassNames } from "@util/helpers";
import Icons from "../../global/icons";
import Heading from "../../global/heading";

interface Props {
  children: ReactNode;
  fullHeight?: boolean;
}

const ResultsCard = ({ children, fullHeight }: Props) => {
  return (
    <Wrapper className={mergeClassNames("results-block", fullHeight && "full-height")}>
      <Icons asset="logoIcon" color="cream" />
      {children ? (
        <>
          {children}
          <Icons asset="logo" color="cream" width={150} />
        </>
      ) : (
        <Heading design="h5" tag="p" className="empty-result-title">
          Click calculate to display your results
        </Heading>
      )}
    </Wrapper>
  );
};

export default ResultsCard;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 50px;

  .empty-result-title {
    max-width: 85%;
  }

  .logo {
    margin: auto auto 0;
  }
  .logoIcon {
    position: absolute;
    top: 25px;
    right: 25px;

    ${mediaQuery.tabletDown} {
      right: 15px;
      top: 15px;
    }
  }
`;
