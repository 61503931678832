import React from "react";

import MaskedInput, { Mask } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import styled from "styled-components";
import { colors, fontSizes } from "@util/constants";
import { getCssVar, mergeClassNames } from "@util/helpers";

const defaultCurrencyMask = {
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  allowNegative: true,
  allowLeadingZeroes: false,
  prefix: "",
};

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: IconType;
  fullWidth?: boolean;
  design?: "cream" | "maroon" | "auto";
  prefix?: string;
  suffix?: string;
  type?: React.HTMLInputTypeAttribute | "currency";
  width?: number;
  currencyMaskOptions?: Mask;
}

const TextField = ({
  icon,
  design = "auto",
  prefix,
  suffix,
  width,
  type,
  fullWidth,
  currencyMaskOptions,
  ...props
}: Props) => {
  const currencyMask = createNumberMask({
    ...defaultCurrencyMask,
    ...currencyMaskOptions,
  });

  return (
    <Wrapper
      width={width}
      className={mergeClassNames(
        "text-field",
        fullWidth && "full-width",
        design && `design ${design}`,
      )}
    >
      {prefix && <span className="prefix">{prefix}</span>}
      {type === "currency" ? <MaskedInput mask={currencyMask} {...props} /> : <input {...props} />}
      {suffix && <span className="suffix">{suffix}</span>}
    </Wrapper>
  );
};

export default TextField;

const Wrapper = styled.div<{ width?: number }>`
  background-color: ${getCssVar("blockBgColor", colors.cream)};
  padding: 8px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  width: ${({ width }) => (width ? `${width}px` : "fit-content")};
  font-size: ${fontSizes.span.default}px;

  &.full-width {
    width: 100%;

    input {
      width: 100%;
    }
  }

  .prefix,
  .suffix {
    width: fit-content;
    display: block;
  }

  input {
    background: none;
    border: none;
    outline: none;
    width: 100%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &.design {
    border: 1px solid;
    border-radius: 4px;
    min-width: 80px;

    .select-icon {
      right: 10px;
    }

    &.cream {
      color: ${colors.cream};
      border-color: ${colors.cream};
    }

    &.maroon {
      color: ${colors.maroon};
      border-color: ${colors.maroon};
    }

    &.auto {
      color: ${getCssVar("blockTextColor", colors.maroon)};
      border-color: ${getCssVar("blockTextColor", colors.maroon)};
    }
  }
`;
