import React, { useEffect, useRef, useState } from "react";

import Icons from "./icons";

import styled from "styled-components";
import Link from "./link";
import { useOnClickOutside } from "@util/hooks";
import { colors, colorsRGB, fontSizes, mediaQuery, zIndex } from "@util/constants";
import { mergeClassNames, getCssVar } from "@util/helpers";

interface Props {
  className?: string;
  design?: "cream" | "maroon" | "auto";
  defaultTitle?: string;
  options: SelectOption[];
  selectedOption?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  showDefaultOption?: boolean;
  onChange?: (value: string) => void;
}

function getSelectedTitle(
  options: SelectOption[],
  defaultTitle: string | undefined,
  selectedOption: string | undefined,
) {
  if (selectedOption) {
    const foundOption = options.find(el => el.value === selectedOption);

    if (foundOption) return foundOption.title;
  }

  if (defaultTitle) return defaultTitle;

  return "Please select";
}

const Select = ({
  design = "auto",
  className,
  selectedOption,
  options,
  defaultTitle,
  showDefaultOption,
  disabled,
  onChange,
  fullWidth,
}: Props) => {
  const [open, setOpen] = useState(false);
  const selectedTitle = getSelectedTitle(options, defaultTitle, selectedOption);
  const width = selectedTitle.length + 2;
  const refSelect = useRef<HTMLDivElement>(null);
  const [listHeight, setListHeight] = useState(200);
  useOnClickOutside(refSelect, () => setOpen(false));

  useEffect(() => {
    if (!refSelect.current) return;

    const rect = refSelect.current?.getBoundingClientRect();
    const distToBottom = window.innerHeight - rect.top - 60;
    const height = distToBottom < 200 ? 200 : distToBottom;

    setListHeight(height);
  }, [open]);

  const handleInputClick = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  const handleSelect = (val?: string) => {
    setOpen(false);
    if (onChange && typeof val === "string") onChange(val);
  };

  if (!options) return null;
  return (
    <Wrapper
      ref={refSelect}
      className={mergeClassNames(
        "select",
        className,
        fullWidth && "full-width",
        design && `design ${design}`,
      )}
      open={open}
      width={width}
      listHeight={listHeight}
    >
      <div className="select-input-wrapper">
        <div className="select-input-click" onClick={handleInputClick}>
          <input aria-disabled={disabled} type="text" value={selectedTitle} readOnly disabled />
        </div>
        <Icons asset="arrowDown" size={16} className="select-icon" />
        <ul className="select-options">
          {defaultTitle && showDefaultOption && (
            <li
              onClick={() => {
                handleSelect("");
              }}
            >
              {defaultTitle}
            </li>
          )}
          {options.filter(Boolean).map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  handleSelect(item.value);
                }}
              >
                {item.link ? <Link data={item.link}>{item.title}</Link> : item.title}
              </li>
            );
          })}
        </ul>
      </div>
    </Wrapper>
  );
};

export default Select;

const Wrapper = styled.div<{
  open: boolean;
  width: number;
  listHeight: number;
}>`
  width: ${({ width }) => width}em;
  min-width: 60px;
  display: flex;
  border-radius: 4px;
  font-size: ${fontSizes.span.default + "px"};

  &.full-width {
    width: 100%;
  }

  .select-input-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;

    ${mediaQuery.tabletDown} {
      max-width: unset;
    }

    .select-input-click {
      width: 100%;
      height: 100%;
      padding: 8px 20px;
    }

    input {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      pointer-events: none;
      width: inherit;
      background: none;
      border: none;
      height: 100%;
      outline: none;
    }
  }

  .select-icon {
    position: absolute;
    right: 0;
    top: 30%;
    transform: ${({ open }) => (open ? "rotate(-180deg)" : "rotate(0)")};
    pointer-events: none;
    transition: all 0.25s ease-in-out;
  }

  .select-options {
    list-style: none;
    position: absolute;
    z-index: ${zIndex.dropdown};
    border: 1px solid ${getCssVar("blockTextColor", colors.maroon)};
    background: ${getCssVar("blockBgColor", colors.cream)};
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: ${({ open }) => (open ? "block" : "none")};
    top: auto;
    left: 0;
    overflow-y: auto;
    max-height: ${({ listHeight }) => listHeight}px;

    li {
      padding: 6px 20px;
      cursor: pointer;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: ${fontSizes.span.default + "px"};
      overflow: hidden;

      ${mediaQuery.tabletDown} {
        white-space: normal;
      }

      &:hover {
        background-color: ${colorsRGB.maroon(0.1)};
      }
    }
  }

  &.design {
    border: 1px solid;
    border-radius: 4px;
    min-width: 80px;

    .select-icon {
      right: 10px;
    }

    &.cream {
      color: ${colors.cream};
      border-color: ${colors.cream};
      background-color: ${getCssVar("blockBgColor", colors.cream)};
    }

    &.maroon {
      color: ${colors.maroon};
      border-color: ${colors.maroon};
      background-color: ${getCssVar("blockBgColor", colors.cream)};
    }

    &.auto {
      background-color: ${getCssVar("blockBgColor", colors.cream)};
      color: ${getCssVar("blockTextColor", colors.maroon)};
      border-color: ${getCssVar("blockTextColor", colors.maroon)};
    }
  }
`;
